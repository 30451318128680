import React, { useState } from 'react';
import GenericRowComponent from 'example-components/shared/genericRowComponent';
import { REST } from 'config/AxiosConfig';

function CustomerRowComponent({ initialCustomer }) {
  const fields = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      show: false
    },
    {
      name: 'fullName',
      label: 'Nume Client',
      type: 'text',
      show: true
    },
    {
      name: 'clientType',
      label: 'Tip Client',
      type: 'select',
      options: [
        { value: 'PERSOANA_FIZICA', label: 'Persoana Fizica' },
        { value: 'PERSOANA_JURIDICA', label: 'Persoana Juridica' }
      ],
      show: false
    },
    {
      name: 'address',
      label: 'Adresa',
      type: 'text',
      show: false
    },
    {
      name: 'iban',
      label: 'IBAN',
      type: 'text',
      show: false
    },
    {
      name: 'taxNo',
      label: 'Tax No',
      type: 'text',
      show: false
    },
    {
      name: 'taxRegNo',
      label: 'taxRegNo',
      type: 'text',
      show: false
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      show: true
    },
    {
      name: 'phoneNo',
      label: 'Numar Telefon',
      type: 'text',
      show: true
    },
    // {
    //   name: 'createdAt',
    //   label: 'Data Creares',
    //   type: 'text'
    // },
    {
      name: 'cnp',
      label: 'CNP',
      type: 'text',
      show: false
    }
  ];

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [customer, setCustomer] = useState(initialCustomer);

  const onEdit = async (updatedCustomer) => {
    try {
      const response = await REST.put(`/client/${customer.id}`, {
        ...updatedCustomer
      });

      setCustomer(updatedCustomer);
    } catch (error) {
      alert('An error occurred while editing the product.');
    }
  };

  const onDelete = async (productToDelete) => {
    try {
      const response = await REST.delete(`/client/${productToDelete.id}`);
      setCustomer(null);
    } catch (error) {
      alert('An error occurred while deleting the product.');
    }
  };

  const openDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDeleteOrder = () => {
    // Perform deletion logic here

    // Close the delete confirmation modal after deletion
    setIsDeleteModalOpen(false);
  };

  if (customer === null) {
    return null; // or some other fallback UI
  }

  return (
    <GenericRowComponent
      rowData={customer}
      fields={fields}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
}

export default CustomerRowComponent;
