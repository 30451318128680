import React from 'react';

import PageLoginIllustration1 from '../../example-components/PageLoginIllustration/PageLoginIllustration1';
export default function PageLoginIllustration() {
  return (
    <>
      <PageLoginIllustration1 />
    </>
  );
}
