import { ThemeProvider } from '@material-ui/styles';
import { PageRegisterIllustration } from 'example-pages';
import { AnimatePresence, motion } from 'framer-motion';
import RouteWithLayout from 'layout-components/RouteWithLayout';
import lscache from 'lscache';
import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ClimbingBoxLoader } from 'react-spinners';
import Customers from './example-pages/Customers';
// Example Pages
import Dashboard from './example-pages/Dashboard';
import Orders from './example-pages/Orders';
import PageError404 from './example-pages/PageError404';
import PageLoginIllustration from './example-pages/PageLoginIllustration';
import Products from './example-pages/Products';
import Transactions from './example-pages/Transactions';
// Layout Blueprints
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import MuiTheme from './theme';

const Routes = () => {
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return lscache.get('isAuth') === 'true' ? (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <RouteWithLayout
              component={Dashboard}
              exact
              layout={LeftSidebar}
              path="/"
            />
            <RouteWithLayout
              component={Dashboard}
              exact
              layout={LeftSidebar}
              path="/Dashboard"
            />
            <RouteWithLayout
              component={Customers}
              exact
              layout={LeftSidebar}
              path="/Customers"
            />
            <RouteWithLayout
              component={Orders}
              exact
              layout={LeftSidebar}
              path="/Orders"
            />
            <RouteWithLayout
              component={Products}
              exact
              layout={LeftSidebar}
              path="/Products"
            />
            <RouteWithLayout
              component={Transactions}
              exact
              layout={LeftSidebar}
              path="/Transactions"
            />
            <RouteWithLayout
              component={PageError404}
              exact
              layout={MinimalLayout}
              path="/PageError404"
            />
            <Redirect to="/PageError404" component={PageError404} />
          </Switch>
          );
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <RouteWithLayout
              component={PageLoginIllustration}
              exact
              layout={MinimalLayout}
              path="/"
            />
            <RouteWithLayout
              component={PageLoginIllustration}
              exact
              layout={MinimalLayout}
              path="/PageLoginIllustration"
            />
            <RouteWithLayout
              component={PageRegisterIllustration}
              exact
              layout={MinimalLayout}
              path="/PageRegisterIllustration"
            />

            <RouteWithLayout
              component={PageError404}
              exact
              layout={MinimalLayout}
              path="/PageError404"
            />
            <Redirect
              to="/PageLoginIllustration"
              component={PageLoginIllustration}
            />
          </Switch>
          );
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
