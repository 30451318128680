import React, { createContext, useContext, useState } from 'react';

// Create a context for the selected products
const SelectedDataContext = createContext();

// Create a context provider to manage the state of selected products
export const SelectedDataProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  return (
    <SelectedDataContext.Provider
      value={{
        selectedProducts,
        setSelectedProducts,
        selectedClient,
        setSelectedClient
      }}>
      {children}
    </SelectedDataContext.Provider>
  );
};

// Custom hook to consume the SelectedDataContext
export const useSelectedData = () => {
  return useContext(SelectedDataContext);
};

export default SelectedDataContext; // Export the context itself (optional)
