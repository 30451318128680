import { Button, Modal } from '@material-ui/core';
import React from 'react';

const OrderDetailsModal = ({ order, isOpen, onClose }) => {
  const { status, id, products, value } = order;
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        className="bg-white mx-auto w-[800px] !mt-20 relative"
        style={{ marginTop: '5rem !important' }}>
        <div
          className={`absolute  ${
            status === 'NEW'
              ? 'bg-green-400'
              : status === 'FAILED'
              ? 'bg-red-400'
              : 'bg-yellow-400'
          } top-0 left-0 px-2 py-1 text-white`}>
          {status || 'No Status'}
        </div>
        <div className="absolute top-0 right-0">
          <button
            className="p-2 text-gray-500 hover:text-gray-700"
            onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-4 text-center">
          <h4 className="text-3xl font-weight-bold mb-2">
            Comanda <span className="text-[#3c44b1]">#{id}</span>
          </h4>
          <h4 className="font-size-xxl font-weight-bold mb-2 text-left">
            <span className="text-[#3c44b1]">{order.clientName}</span>
          </h4>

          <div className="container m-auto">
            <div className="flex flex-col justify-center">
              <table>
                <thead
                  style={{
                    boxShadow: '0 0.25rem 0.55rem rgba(60, 68, 177, 0.35)'
                  }}
                  className=" bg-[#3c44b1] w-full py-2 text-white rounded-sm mb-2">
                  <tr>
                    <td className="px-4 py-2">Nr.crt</td>
                    <td className="px-4 py-2">Nume produs</td>
                    <td className="px-4 py-2">U.M.</td>
                    <td className="px-4 py-2">Cantitate</td>
                    <td className="px-4 py-2">Pret</td>
                    <td className="px-4 py-2">Valoare</td>
                    <td className="px-4 py-2"></td>
                  </tr>
                </thead>
                {products.map((product, index) => {
                  return (
                    <>
                      <tr className="text-center" key={index}>
                        <td className="py-2">{index + 1}</td>
                        <td className="py-2">{product.name}</td>
                        <td className="py-2">{product.unit}</td>
                        <td className="py-2">{product.amount}</td>
                        <td className="text-right py-2">{product.price}</td>
                        <td className="text-right py-2">
                          {Number(product.price) * Number(product.amount)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
              <div className="border-solid border-t border-t-gray-200 h-px my-6"></div>
              <h1 className="text-2xl lg:text-4xl text-right">
                Total: <span className=" text-[#3c44b1]">{value}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;
