import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

export const DetailsModal = ({ isOpen, closeModal, data, fields }) => {
  return (
    <Dialog
      scroll="body"
      open={isOpen}
      onClose={closeModal}
      maxWidth="md"
      classes={{ paper: 'w-100 border-0 bg-white p-4' }}>
      <DialogTitle>Detalii</DialogTitle>
      <DialogContent>
        {fields.map((field, index) => {
          return (
            <p key={index}>
              <strong>{field.label}:</strong>{' '}
              {field.type === 'html' ? (
                <span dangerouslySetInnerHTML={{ __html: data[field?.name] }} />
              ) : (
                data[field?.name]
              )}
            </p>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="contained" color="primary">
          Inchide
        </Button>
      </DialogActions>
    </Dialog>
  );
};
