import React from 'react';

import { PageTitle } from '../../layout-components';

import ProductsMainSection from '../../example-components/Products/ProductsMainSection';
import ProductsHeader from '../../example-components/Products/ProductsHeader';
export default function Products() {
  return (
    <>
      <PageTitle titleHeading="Produse" titleDescription="">
        <ProductsHeader />
      </PageTitle>

      <ProductsMainSection />
    </>
  );
}
