import React, { createContext, useContext, useState } from 'react';

// Create a context for the selected data
const OrdersContext = createContext();

// Create a context provider to manage the state of selected orders
export const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);

  return (
    <OrdersContext.Provider
      value={{
        orders,
        setOrders
      }}>
      {children}
    </OrdersContext.Provider>
  );
};

// Custom hook to consume the OrdersContext
export const useOrders = () => {
  return useContext(OrdersContext);
};

export default OrdersContext; // Export the context itself (optional)
