import React, { useState } from 'react';
import OrderDetailsModal from './OrderDetailsModal';
import OrderEditDetailsModal from './OrderEditDetailsModal';
import { useSelectedData } from 'contexts/SelectedProductsContext';
import { DeleteModal } from 'example-components/shared/DeleteModal';
import { RowButtonsContainer } from 'utils/rowUtils';
import { REST } from 'config/AxiosConfig';

const OrderRow = ({ initialOrder }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { selectedClient, setSelectedClient } = useSelectedData(null);
  const [order, setOrder] = useState(initialOrder);

  const openDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedClient(null);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDeleteOrder = async () => {
    try {
      const response = REST.delete(`/order/${order.id}`);
      setOrder(null);
    } catch (error) {
      alert('An error occurred while deleting the order.');
    }
    setIsDeleteModalOpen(false);
  };
  console.log('order is here', order);

  const handleEdit = async (selectedProducts, selectedClient, statusValue) => {
    const value = selectedProducts.reduce(
      (total, product) => total + product.price * product.amount,
      0
    );
    console.log('selectedProducts', selectedProducts);
    console.log('value', value);
    const body = {
      id: order.id,
      clientId: selectedClient?.id,
      products: [
        ...selectedProducts.map((product) => ({
          productId: product.productId,
          amount: product.amount
        }))
      ],
      value,
      status: statusValue.value
    };
    await REST.put(`/order/${order.id}`, body);
    console.log({
      ...body,
      clientName: selectedClient?.fullName
    });
    setOrder({
      ...body,
      clientName: selectedClient?.fullName,
      products: selectedProducts
    });
    closeEditModal();
  };

  if (order)
    return (
      <>
        <tr>
          <td className="text-center">
            <span className="font-weight-bold">{order?.id}</span>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <div>
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="font-weight-bold text-black"
                  title="...">
                  {order?.clientName}
                </a>
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center gap-4">
              {order?.products.slice(0, 3).map((product) => (
                <div className="bg-[#3c44b1] rounded-sm p-2 text-white">
                  {product.name}
                </div>
              ))}
            </div>
          </td>
          <td>
            <div
              className={`px-4 py-1 h-auto  border-1  badge ${
                order.status === 'NEW'
                  ? 'badge-neutral-success text-success border-success'
                  : order.status === 'IN_PROGRESS'
                  ? 'badge-neutral-warning text-warning border-warning'
                  : 'badge-neutral-danger text-danger border-danger'
              }`}>
              {order?.status}
            </div>
          </td>
          <td className="font-size-lg font-weight-bold text-right">
            <span>{order?.value}</span>
            <small> RON</small>
          </td>
          <RowButtonsContainer
            openDetailsModal={openDetailsModal}
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
          />

          {/* Render the OrderDetailsDetailsModal  */}
          <OrderDetailsModal
            order={order}
            isOpen={isDetailsModalOpen}
            onClose={closeDetailsModal}
          />

          {/* Render the EditDetailsModal  */}
          {isEditModalOpen && (
            <OrderEditDetailsModal
              order={order}
              isOpen={isEditModalOpen}
              onClose={closeEditModal}
              onEdit={handleEdit}
            />
          )}
        </tr>

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            closeDeleteModal={closeDeleteModal}
            confirmDeleteOrder={confirmDeleteOrder}
          />
        )}
        <tr className="divider"></tr>
      </>
    );
  else return <></>;
};

export default OrderRow;
