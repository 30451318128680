import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { REST } from 'config/AxiosConfig';

const AccountsPageTitleActions = () => {
  const [modal, setModal] = useState(false);
  const [pfForm, setPfForm] = useState(false);
  const [pjForm, setPjForm] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [description, setDescription] = useState('');

  const handleSave = async () => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        description: description
      }
    });

    await REST.post(`/client`, formState.values)
      .then((response) => {
        console.log(response.data, 'CLIENT_NOU');

        // window.location.href = "/Dashboard";
      })
      .catch((error) => {
        // todo handle errors
        console.log(error, 'ERROR>>>>');
      });
    window.location.href = '/Customers';

    toggleModal();
  };
  const [formState, setFormState] = useState({
    values: {}
  });
  const showpfForm = () => {
    setPfForm(!pfForm);
    setPjForm(false);
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        clientType: 'PERSOANA_FIZICA'
      }
    });
  };
  const showpjForm = () => {
    setPjForm(!pjForm);
    setPfForm(false);
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        clientType: 'PERSOANA_JURIDICA'
      }
    });
  };

  const handleFormChange = (event) => {
    event.persist();
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    });
  };

  console.log(formState, 'FORMSTATE>>>>>');

  return (
    <>
      <Button onClick={toggleModal} variant="contained" color="primary">
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </span>
        <span className="btn-wrapper--label">Adauga Client Nou</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4 text-center">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Adauga Client Nou
          </h4>
          <p className="text-black-50 mb-0">
            Completeaza urmatorul formular pentru adaugarea unui client nou
          </p>
        </div>
        <div className="divider bg-dark opacity-2" />
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">Nume</div>
          <p className="font-size-sm text-black-50">Nume complet</p>
          <TextField
            fullWidth
            placeholder="Nume..."
            labelWidth={0}
            variant="outlined"
            value={formState.values?.fullName || ''}
            onChange={handleFormChange}
            name="fullName"
          />
        </div>
        <div className="divider bg-dark opacity-2" />
        <div
          className="p-4 rounded-bottom d-flex justify-content-center bg-secondary"
          style={{ padding: '5px' }}>
          <Button
            onClick={showpfForm}
            variant="contained"
            color="primary"
            style={{ marginRight: '20px' }}>
            Persoana Fizica
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '20px' }}
            onClick={showpjForm}>
            Persoana Juridica
          </Button>
        </div>
        {pfForm && (
          <div>
            <div className="p-4 text-center"> Date persoana fizica</div>
            <div>
              <div className="p-4">
                <div className="font-weight-bold font-size-lg">CNP</div>
                <p className="font-size-sm text-black-50">CNP</p>
                <TextField
                  fullWidth
                  placeholder="CNP..."
                  labelWidth={0}
                  variant="outlined"
                  value={formState.values.cnp || ''}
                  onChange={handleFormChange}
                  name="cnp"
                />
              </div>
            </div>
          </div>
        )}

        {pjForm && (
          <div>
            <div className="p-2 text-center"> Date persoana juridica</div>
            <div>
              <div className="p-4">
                <div className="font-weight-bold font-size-lg">CUI/CIF</div>
                <p className="font-size-sm text-black-50">CUI/CIF</p>
                <TextField
                  fullWidth
                  placeholder="CUI/CIF..."
                  labelWidth={0}
                  variant="outlined"
                  value={formState.values?.taxNo || ''}
                  onChange={handleFormChange}
                  name="taxNo"
                />
              </div>
              <div className="p-4">
                <div className="font-weight-bold font-size-lg">REG NO.</div>
                <p className="font-size-sm text-black-50">REG NO.</p>
                <TextField
                  fullWidth
                  placeholder="REG NO..."
                  labelWidth={0}
                  variant="outlined"
                  value={formState.values?.taxRegNo || ''}
                  onChange={handleFormChange}
                  name="taxRegNo"
                />
              </div>
            </div>
          </div>
        )}
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">ADRESA</div>
          <p className="font-size-sm text-black-50">ADRESA</p>
          <TextField
            fullWidth
            placeholder="ADRESA..."
            labelWidth={0}
            variant="outlined"
            value={formState.values.address || ''}
            onChange={handleFormChange}
            name="address"
          />
        </div>
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">IBAN</div>
          <p className="font-size-sm text-black-50">IBAN</p>
          <TextField
            fullWidth
            placeholder="IBAN..."
            labelWidth={0}
            variant="outlined"
            value={formState.values.iban || ''}
            onChange={handleFormChange}
            name="iban"
          />
        </div>
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">EMAIL</div>
          <p className="font-size-sm text-black-50">EMAIL</p>
          <TextField
            fullWidth
            placeholder="EMAIL..."
            labelWidth={0}
            variant="outlined"
            value={formState.values?.email || ''}
            onChange={handleFormChange}
            name="email"
          />
        </div>
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">TELEFON</div>
          <p className="font-size-sm text-black-50">TELEFON</p>
          <TextField
            fullWidth
            placeholder="TELEFON..."
            labelWidth={0}
            variant="outlined"
            value={formState.values?.phoneNo || ''}
            onChange={handleFormChange}
            name="phoneNo"
          />
        </div>

        <div className="divider bg-dark opacity-2" />

        <div className="divider bg-dark opacity-2" />

        <div className="divider bg-dark opacity-3" />
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral-danger font-size-sm mr-4">
            Anuleaza
          </Button>
          <Button className="btn-success font-weight-bold" onClick={handleSave}>
            Salveaza
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default AccountsPageTitleActions;
