import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog } from '@material-ui/core';
import { REST } from 'config/AxiosConfig';

import SelectComponent from './selectComponent';
import SelectProductComponent from './selectProductComponent';
import { useSelectedData } from 'contexts/SelectedProductsContext';
import { useOrders } from 'contexts/OrdersContext';
import OrderDialog from './OrderDialog';

const OrdersHeader = () => {
  const [customers, setCustomers] = useState([]);
  const { orders, setOrders } = useOrders();
  const {
    selectedProducts,
    setSelectedProducts,
    selectedClient,
    setSelectedClient
  } = useSelectedData();

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    getCustomers();
  };

  const handleSelect = (event, client) => {
    setSelectedClient(client);
  };
  const handleCancel = () => {
    setSelectedProducts([]);
    toggleModal();
  };
  const handleSave = async () => {
    const body = createOrderBody(selectedProducts, selectedClient);

    try {
      await postOrder(body);
      setSelectedProducts([]);
      console.log('Succes');

      const newOrder = {
        id: orders.length + 1,
        products: selectedProducts,
        clientId: selectedClient?.id,
        clientName: selectedClient?.fullName,
        value: body.value,
        status: body.status
      };
      setOrders([...orders, newOrder]);
      setModal(false);
    } catch (error) {
      console.log('Error', error);
    }
  };

  const getCustomers = async () => {
    await REST.get(`/client/getAll`)
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  return (
    <>
      <Button className="btn-primary" onClick={toggleModal}>
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </span>
        <span className="btn-wrapper--label">Adauga Comanda</span>
      </Button>

      <OrderDialog
        open={modal}
        onClose={toggleModal}
        customers={customers}
        handleSelect={handleSelect}
        selectedProducts={selectedProducts}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </>
  );
};

const calculateTotalValue = (products) => {
  return products.reduce((total, product) => total + product.price, 0);
};

const mapProductsForOrder = (products) => {
  return products.map((product) => ({
    productId: product.id,
    amount: product.amount
  }));
};

const createOrderBody = (products, client) => {
  return {
    clientId: client?.id,
    products: mapProductsForOrder(products),
    value: calculateTotalValue(products),
    status: 'NEW'
  };
};
const postOrder = async (body) => {
  await REST.post('/order', body);
};

export default OrdersHeader;
