import React from 'react';

import { PageTitle } from '../../layout-components';

import OrdersMainSection from '../../example-components/Orders/OrdersMainSection';
import OrdersHeader from '../../example-components/Orders/OrdersHeader';
export default function Orders() {
  return (
    <>
      <PageTitle titleHeading="Comenzi" titleDescription="">
        <OrdersHeader />
      </PageTitle>

      <OrdersMainSection />
    </>
  );
}
