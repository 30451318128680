import React, { useEffect } from 'react';

import { Table } from '@material-ui/core';

import { REST } from 'config/AxiosConfig';
import OrderRow from './OrderRow';
import { useOrders } from 'contexts/OrdersContext';

const OrdersTable = () => {
  const { orders, setOrders } = useOrders();

  const getAllOrders = async () => {
    try {
      const response = await REST.get('/order/getAll');
      setOrders(response.data);
      console.log('orders', response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <div className="p-4">
      <div className="table-responsive-md">
        <Table className="table table-alternate-spaced mb-0">
          <thead>
            <tr>
              <th
                style={{
                  width: '110px'
                }}
                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark text-center"
                scope="col">
                Order
              </th>
              <th
                style={{
                  width: '300px'
                }}
                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                scope="col">
                Client
              </th>
              <th
                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                scope="col">
                Product
              </th>
              <th
                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                scope="col">
                Status
              </th>
              <th
                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                scope="col">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="divider"></tr>
            {console.log('orders', orders)}
            {orders.map((order) => (
              <OrderRow initialOrder={order} key={order.id} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default OrdersTable;
