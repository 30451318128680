import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { MenuItem } from 'react-contextmenu';
import ReactQuill from 'react-quill';

const EditModal = ({ isOpen, closeModal, data, fields, onSave }) => {
  const [editedData, setEditedData] = useState(data);

  const handleEdit = async () => {
    try {
      await onSave(editedData);
    } catch (error) {
      alert('An error occurred while editing the product.');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCustomFieldChange = (value, fieldName) => {
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            label={field.label}
            name={field.name}
            type={field.type}
            value={editedData[field.name]}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        );
      case 'html':
        return (
          <ReactQuill
            theme="snow"
            value={editedData[field.name]}
            onChange={(value) => handleCustomFieldChange(value, field.name)}
            placeholder={field.placeholder}
          />
        );
      case 'select':
        return (
          <FormControl variant="outlined" size="medium" className="w-100">
            <InputLabel id={`${field.name}-select-label`}>
              {field.label}
            </InputLabel>
            <Select
              labelId={`${field.name}-select-label`}
              id={`${field.name}-select-label-id`}
              fullWidth
              label={field.label}
              value={editedData[field.name]}
              onChange={handleInputChange}
              name={field.name}>
              {field.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      maxWidth="md"
      classes={{ paper: 'w-100 border-0 bg-white p-4' }}>
      <DialogTitle id="form-dialog-title">
        Editeaza {data.name || data.fullName}
      </DialogTitle>
      <DialogContent>
        {fields.map((field) => (
          <div key={field.name} className="p-4">
            {renderField(field)}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          className="font-size-sm mr-2 ">
          Anuleaza
        </Button>
        <Button variant="contained" onClick={handleEdit} color="primary">
          Salveaza
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
