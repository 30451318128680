import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';

export const RowButtonsContainer = ({
  openDetailsModal,
  openEditModal,
  openDeleteModal
}) => (
  <td className="text-right">
    <Button
      className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
      onClick={openDetailsModal} // Open the Detailsmodal when this button is clicked
    >
      <FontAwesomeIcon icon={['fas', 'search']} className="font-size-sm" />
    </Button>
    <Button
      className="btn-first mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
      onClick={openEditModal}>
      <FontAwesomeIcon icon={['far', 'edit']} className="font-size-sm" />
    </Button>
    <Button
      className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
      onClick={openDeleteModal} // Open the delete confirmation modal
    >
      <FontAwesomeIcon icon={['fas', 'times']} className="font-size-sm" />
    </Button>
  </td>
);
