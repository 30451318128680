import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelectedData } from 'contexts/SelectedProductsContext';

function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

const SelectComponent = ({
  label,
  data,
  unit = null,
  handler,
  width,
  initialValue
}) => {
  let values = data;
  return (
    <div className="overflow-hidden p-0 ">
      <div>
        <div className="font-weight-bold font-size-lg">Selecteaza {label}</div>
        <Grid container>
          <Grid item md={12}>
            <FormControl variant="outlined" size="medium" className="w-100">
              <Autocomplete
                id={`${label}-autocomplete`}
                options={data}
                getOptionLabel={(option) =>
                  option.fullName || option.label || option.name || ''
                }
                onChange={(event, newValue) => handler(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={capitalizeFirstLetter(label)}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SelectComponent;
