import React, { useState } from 'react';

import GenericRowComponent from '../shared/genericRowComponent';
import ProductEditModal from './productEditModal';
import ProductViewModal from './productViewModalComponent';
import { REST } from 'config/AxiosConfig';

function ProductRow({ initialProduct }) {
  const [product, setProduct] = useState(initialProduct);

  const fields = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'price', label: 'Price', type: 'text' },
    { name: 'description', label: 'Description', type: 'html' },
    { name: 'unit', label: 'Unit', type: 'text' }
  ];

  const onEdit = async (updatedProduct) => {
    try {
      const response = await REST.put(`/products/${product.id}`, {
        name: updatedProduct.name,
        price: updatedProduct.price,
        description: updatedProduct.description,
        unit: updatedProduct.unit
      });

      setProduct(updatedProduct);
    } catch (error) {
      alert('An error occurred while editing the product.');
    }
  };

  const onDelete = async (productToDelete) => {
    try {
      const response = await REST.delete(`/products/${productToDelete.id}`);
      setProduct(null);
    } catch (error) {
      alert('An error occurred while deleting the product.');
    }
  };

  if (product === null) {
    return null; // or some other fallback UI
  }

  return (
    <GenericRowComponent
      rowData={product}
      fields={fields}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
}

export default ProductRow;
