import React, { useState } from 'react';
import { RowButtonsContainer } from 'utils/rowUtils';
import { DeleteModal } from './DeleteModal';
import EditModal from './EditModal';
import { DetailsModal } from './DetailsModal';

function GenericRowComponent({ rowData, fields, onEdit, onDelete }) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  console.log('rowData', rowData);

  const openDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDeleteOrder = async (rowdata) => {
    try {
      onDelete(rowdata);
      setIsDeleteModalOpen(false);
    } catch (error) {
      alert('An error occurred while deleting the product.');
    }
  };

  const handleEdit = async (updatedProduct) => {
    try {
      onEdit(updatedProduct);
    } catch (error) {
      alert('An error occurred while editing the product.');
    }
    closeEditModal();
  };

  return (
    <>
      <tr>
        {fields.map(
          (field, index) =>
            field.show !== false && (
              <td key={index}>
                {/* Render data based on the field */}
                {field?.type === 'html' ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: rowData[field?.name] }}
                  />
                ) : (
                  rowData[field?.name]
                )}
              </td>
            )
        )}
        <RowButtonsContainer
          openDetailsModal={openDetailsModal}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
        />
      </tr>
      <tr className="divider"></tr>

      {/* Edit Modal Component */}
      {isEditModalOpen && (
        <EditModal
          data={rowData}
          isOpen={isEditModalOpen}
          closeModal={closeEditModal}
          fields={fields}
          onSave={handleEdit}
        />
      )}

      {/* View Modal Component */}
      <DetailsModal
        data={rowData}
        isOpen={isDetailsModalOpen}
        closeModal={closeDetailsModal}
        fields={fields}
      />

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          data={rowData}
          isOpen={isDeleteModalOpen}
          closeDeleteModal={closeDeleteModal}
          confirmDeleteOrder={confirmDeleteOrder}
        />
      )}
    </>
  );
}

export default GenericRowComponent;
