import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import SelectComponent from './selectComponent';
import SelectProductComponent from './selectProductComponent';

const OrderDialog = ({
  open,
  onClose,
  customers,
  handleSelect,
  selectedProducts,
  handleSave,
  handleCancel
}) => {
  return (
    <Dialog
      scroll="body"
      open={open}
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: 'w-100 border-0 bg-white' }}>
      <div className="p-4 text-center">
        <h4 className="font-size-xxl font-weight-bold mb-2">
          Adauga o comanda noua
        </h4>
        <p className="text-black-50 mb-0">
          Completeaza urmatorul formular pentru a aduga o comanda noua
        </p>
      </div>
      <div className="divider bg-dark opacity-2" />

      <div className="container p-4">
        <div className="mb-4">
          <SelectComponent
            label={'client'}
            data={customers}
            handler={handleSelect}
          />
        </div>

        <SelectProductComponent />
      </div>

      <div className="divider bg-dark opacity-2" />

      <div className="divider bg-dark opacity-3" />
      <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
        <Button
          onClick={handleCancel}
          className="btn-neutral-danger font-size-sm mr-4">
          Anuleaza
        </Button>
        <Button
          className="btn-success font-weight-bold"
          onClick={() => handleSave(selectedProducts)}>
          Salveaza
        </Button>
      </div>
    </Dialog>
  );
};

export default OrderDialog;
