import React from 'react';

import PageRegisterIllustration1 from '../../example-components/PageRegisterIllustration/PageRegisterIllustration1';
export default function PageRegisterIllustration() {
  return (
    <>
      <PageRegisterIllustration1 />
    </>
  );
}
