import React from 'react';
import { useSelectedData } from 'contexts/SelectedProductsContext';
import './productDetails.css';
import TotalComponent from './totalComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductList = ({ products }) => {
  const { selectedProducts, setSelectedProducts } = useSelectedData();

  const removeProductFromList = (product) => {
    let updatedSelectedProducts = { ...selectedProducts };
    updatedSelectedProducts = Object.values(updatedSelectedProducts).filter(
      (selectedProduct) => selectedProduct !== product
    );
    setSelectedProducts(updatedSelectedProducts);
  };

  const changeAmount = (e, product) => {
    const updatedSelectedProducts = [...selectedProducts];

    const foundProduct = updatedSelectedProducts.find(
      (item) => item.name === product.name
    );
    foundProduct.amount = Number(e.target.value);
    setSelectedProducts(updatedSelectedProducts);
  };

  return (
    <div>
      {products ? (
        <>
          <table className="w-full">
            <thead
              style={{ boxShadow: '0 0.25rem 0.55rem rgba(60, 68, 177, 0.35)' }}
              className=" bg-[#3c44b1] w-full py-2 text-white rounded-sm mb-2">
              <tr>
                <td className="px-4 py-2">Nr.crt</td>
                <td className="px-4 py-2">Nume produs</td>
                <td className="px-4 py-2">U.M.</td>
                <td className="px-4 py-2">Cantitate</td>
                <td className="px-4 py-2">Pret</td>
                <td className="px-4 py-2">Valoare</td>
                <td className="px-4 py-2"></td>
              </tr>
            </thead>
            <tbody>
              {products?.map((product, index) => {
                return (
                  <tr className="text-center" key={index}>
                    <td className="py-2">{index + 1}</td>
                    <td className="py-2">{product?.name}</td>
                    <td className="py-2">{product.unit}</td>
                    <td className="py-2">
                      <input
                        className="w-[50px] m-0"
                        type="number"
                        min="1"
                        value={product.amount || product.amount}
                        onChange={(e) => changeAmount(e, product)}
                      />
                    </td>
                    <td className="text-right py-2">{product.price}</td>
                    <td className="text-right py-2">
                      {product.price * (product.amount || product.amount)}
                    </td>
                    <td onClick={() => removeProductFromList(product)}>
                      <FontAwesomeIcon
                        className="cursor-pointer text-[#3c44b1] "
                        icon={['fa', 'trash']}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <TotalComponent />
        </>
      ) : (
        <p>Loading...</p> // Or any other loading indicator
      )}
    </div>
  );
};

export default ProductList;
