import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { REST } from 'config/AxiosConfig';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';

const ProductsHeader = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [description, setDescription] = useState('');
  const [unit, setUnit] = useState('');
  const [formState, setFormState] = useState({
    values: {}
  });

  const handleFormChange = (event) => {
    event.persist();
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleType = (event) => {
    setUnit(event.target.value);
  };

  const handleSave = async () => {
    const body = {
      ...formState.values,
      description: description,
      unit: unit
    };
    await REST.post(`/products`, body)
      .then((response) => {
        console.log(response.data, 'PRODUS_NOU');
      })
      .catch((error) => {
        // todo handle errors
        console.log(error, 'ERROR>>>>');
      });
    window.location.href = '/Products';
    toggleModal();
  };

  return (
    <>
      <Button onClick={toggleModal} className="btn-primary">
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </span>
        <span className="btn-wrapper--label">Creaza Produs</span>
      </Button>

      <Dialog
        scroll="body"
        open={modal}
        maxWidth="md"
        onClose={toggleModal}
        classes={{ paper: 'w-100 border-0 bg-white' }}>
        <div className="p-4 text-center">
          <h4 className="font-size-xxl font-weight-bold mb-2">
            Adauga un produs nou
          </h4>
          <p className="text-black-50 mb-0">
            Completeaza urmatorul formular pentru a aduga un produs nou
          </p>
        </div>
        <div className="divider bg-dark opacity-2" />
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">Numele produsului</div>

          <TextField
            fullWidth
            placeholder="Titlu produs..."
            labelWidth={0}
            variant="outlined"
            value={formState.values?.name || ''}
            onChange={handleFormChange}
            name="name"
          />
        </div>
        <div className="divider bg-dark opacity-2" />
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">Pret</div>
          <TextField
            fullWidth
            placeholder="Pret produs..."
            labelWidth={0}
            variant="outlined"
            value={formState.values?.price || ''}
            onChange={handleFormChange}
            name="price"
          />
        </div>

        <div className="divider bg-dark opacity-2" />
        <div className="p-4">
          <div className="font-weight-bold font-size-lg">Descriere</div>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            placeholder="Descriere..."
          />
        </div>

        <div
          className="dropdown-menu-xxl overflow-hidden p-0"
          style={{ width: '100%' }}>
          <div className="p-4">
            <div className="font-weight-bold font-size-lg">
              Unitate de masura
            </div>
            <Grid container spacing={12}>
              <Grid item md={12}>
                <FormControl variant="outlined" size="medium" className="w-100">
                  <InputLabel id="type-select-label">
                    Unitate de masura
                  </InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select-label-id"
                    fullWidth
                    label="Unitate de masura"
                    value={unit}
                    onChange={handleType}>
                    <MenuItem value={'PRODUCT'}>Produs</MenuItem>
                    <MenuItem value={'SERVICE'}>Serviciu</MenuItem>
                    <MenuItem value={'TONA'}>Tona</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="divider bg-dark opacity-3" />
        <div className="p-4 rounded-bottom d-flex justify-content-end bg-secondary">
          <Button
            onClick={toggleModal}
            className="btn-neutral-danger font-size-sm mr-4">
            Anuleaza
          </Button>
          <Button className="btn-success font-weight-bold" onClick={handleSave}>
            Salveaza
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ProductsHeader;
