import React, { useEffect, useState } from 'react';
import SelectComponent from './selectComponent';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelectedData } from 'contexts/SelectedProductsContext';
import { REST } from 'config/AxiosConfig';

const SelectProductHeader = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(1);
  const { selectedProducts, setSelectedProducts } = useSelectedData();

  useEffect(() => {
    getProducts();
  }, []);

  const handleAmountChange = (event) => {
    setSelectedAmount(Number(event.target.value));
  };

  const handleSelectProduct = (event, newValue) => {
    setProduct(newValue);
  };

  const addProductToList = () => {
    if (product) {
      const updatedSelectedProducts = [...selectedProducts];

      const foundProduct = updatedSelectedProducts.find(
        (item) => item.name === product.name
      );

      if (foundProduct) {
        foundProduct.amount += selectedAmount;
      } else {
        updatedSelectedProducts.push({
          ...product,
          amount: selectedAmount
        });
      }

      setSelectedProducts(updatedSelectedProducts);
    }
  };

  const getProducts = async () => {
    try {
      const response = await REST.get(`/products/getAll`);
      setProducts(response.data);
    } catch (error) {
      alert('An error occurred while fetching products:', error);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-5 mb-4">
      <SelectComponent
        style={{ width: '300px' }}
        label={'produs'}
        data={products}
        handler={handleSelectProduct}
      />

      <div>
        <div className="font-weight-bold font-size-lg text-center">
          Cantitate
        </div>
        <input
          className="w-[50px] text-center block pt-3"
          type="number"
          min="1"
          value={selectedAmount}
          onChange={handleAmountChange}
        />
      </div>

      <div>
        <div className="font-weight-bold font-size-lg text-center">
          Pret Unitar
        </div>
        {product && (
          <div className="flex items-center pt-3 ">{product.price}</div>
        )}
      </div>

      <Button
        className="btn-primary self-center !mt-[1.55rem]"
        onClick={addProductToList}>
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </span>
        <span className="btn-wrapper--label">Adauga</span>
      </Button>
    </div>
  );
};

export default SelectProductHeader;
