import axios from 'axios';
import lscache from 'lscache';

// const lang = localStorage.getItem("lang");

// export const baseUrl = "https://core-tuiasi.blacksquare.solutions/api"


const baseUrl = 'https://crmcore.realgrowsoft.com/api';


// console.log(lang);
export const REST = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    'X-engage-initiator': 'frontend',
    'Content-Type': `application/json`
  }
});

REST.interceptors.request.use(
  async (config) => {
    const token = lscache.get('authToken');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const checkStatus = (status) => {
  return {
    is200: status === 200,
    is202: status === 202,
    is401: status === 401,
    is403: status === 403,
    is405: status === 405,
    is404: status === 404,
    is500: status === 500,
    is501: status === 501
  };
};

REST.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  async (error) => {
    const requestStatus = checkStatus(error?.response?.status);

    // the user is not authorized
    if (requestStatus.is401) {
      // If token is not valid or expired clear lscache.
      // Also, here you can do refresh token API call
      // if (error.response.config.url !== 'authenticate') {
      window.location.href = '/';
      lscache.flush();
      // }
    }
    return Promise.reject(error);
  }
);
