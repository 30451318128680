import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

export const DeleteModal = ({
  data,
  isOpen,
  closeDeleteModal,
  confirmDeleteOrder
}) => {
  return (
    <Dialog open={isOpen} onClose={closeDeleteModal}>
      <DialogTitle>Confirmare</DialogTitle>
      <DialogContent>
        <p>Sigur vrei sa stergi aceste date?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDeleteModal} color="secondary">
          Anuleaza
        </Button>
        <Button onClick={() => confirmDeleteOrder(data)} color="primary">
          Sterge
        </Button>
      </DialogActions>
    </Dialog>
  );
};
