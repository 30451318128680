import React from 'react';

import { useSelectedData } from 'contexts/SelectedProductsContext';
const TotalComponent = () => {
  const { selectedProducts } = useSelectedData();

  const calculateTotalValueOfSelectedProducts = () => {
    let totalValue = 0;

    for (const product of Object.values(selectedProducts)) {
      const { price, amount } = product;
      totalValue += amount * price;
    }
    return totalValue;
  };

  const totalValue = calculateTotalValueOfSelectedProducts();

  return (
    <>
      {Object.keys(selectedProducts).length > 0 && (
        <div className="border-solid border-t border-t-gray-200 h-px my-6"></div>
      )}
      <div className="flex justify-end my-2">
        <h1 className="text-2xl lg:text-4xl">
          Total: <span className=" text-[#3c44b1]">{totalValue}</span>
        </h1>
      </div>
    </>
  );
};

export default TotalComponent;
