import { REST } from 'config/AxiosConfig';
import React, { useEffect, useState } from 'react';
import SelectComponent from './selectComponent';
import ProductList from './productList';
import { useSelectedData } from 'contexts/SelectedProductsContext';
import SelectProductHeader from './selectProductHeaderComponent';
const SelectProductComponent = () => {
  const { selectedProducts } = useSelectedData();

  return (
    <>
      <SelectProductHeader />
      <ProductList products={selectedProducts} />
    </>
  );
};

export default SelectProductComponent;
